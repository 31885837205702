<template>
  <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
    :no-content="noContent"
    title="Files"
  >
    <vbt-table
      :headers="headers"
      :items="complaintFiles"
    >
      <template #path="{ item: { path } }">
        <a
          :href="path"
          class="blue--text darken-1 sm-4"
          target="_blank"
        >
        <img width="200px"
          :src="path"
          alt="Screenshot"
         />
        </a>
      </template>
    </vbt-table>
  </vbt-content-box>
</template>

<script>
import { mapState } from 'vuex';

const headers = Object.freeze([
  { text: 'Screenshot', value: 'path' },
  { text: 'Timestamp', value: 'timestamp' },
]);

export default {
  name: 'ComplaintFiles',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingError: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      headers,
    };
  },

  computed: {
    ...mapState('complaints', {
      complaintFiles: ({ complaint: { metadata: { files: items } } }) => items || [],
    }),

    noContent() {
      return this.complaintFiles.length < 1;
    },
  },
};
</script>
