<template>
  <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
    :no-content="noContent"
    title="History"
  >
    <vbt-table
      :headers="headers"
      :items="complaintHistory"
      :page-size="5"
      show-paginator
    >
      <template #processed="{ item: { processed } }">
        {{ processed | formatDate('local', '-') }}
      </template>

      <template #messageId="{ item: { messageId } }">
        <router-link
          :to="{
            name: 'messages',
            query: {
              pageNum: '1',
              messageId,
            },
          }"
          class="blue--text darken-1"
          target="_blank"
        >
          {{ messageId }}
        </router-link>
      </template>

      <template #action="{ item: { action } }">
        {{ complaintActionsByActionId[action] }}
      </template>
      <template #status="{ item: { status } }">
        {{ complaintStatusByStatusId[status] }}
      </template>
    </vbt-table>
  </vbt-content-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

const headers = Object.freeze([
  { text: 'Processed Date', value: 'processed' },
  { text: 'Message', value: 'messageId' },
  { text: 'Action', value: 'action' },
  { text: 'Status', value: 'status' },
  { text: 'Description', value: 'description' },
]);

export default {
  name: 'ComplaintHistory',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingError: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      headers,
    };
  },

  computed: {
    ...mapState('complaints', {
      complaintHistory: ({ complaint: { history: items } }) => items || [],
    }),

    ...mapGetters('enums', ['complaintActionsByActionId']),
    ...mapGetters('enums', ['complaintStatusByStatusId']),

    noContent() {
      return this.complaintHistory.length < 1;
    },
  },
};
</script>
