<template>
  <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
    title="Information"
  >
    <template #toolbar>
      <complaint-approve
        :loading="loading"
        @success="$emit('refresh')"
      />
      <complaint-review
        :loading="loading"
        @success="$emit('refresh')"
      />
    </template>

    <v-layout>
      <v-flex sm4>
        <strong>
          Order ID:
        </strong>

        <router-link
          :to="{
            name: 'order',
            params: { id: complaint.orderId },
          }"
          class="blue--text darken-1"
          target="_blank"
        >
          {{ complaint.orderId }}
        </router-link>
      </v-flex>

      <v-flex sm4>
        <strong>
          Client Order Item ID:
        </strong>

        {{ complaint.orderItemId }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Status:
        </strong>

        <complaint-status-label :status="complaint.status" />
      </v-flex>
    </v-layout>

    <v-layout class="mt-2">
      <v-flex sm4>
        <strong>
          Created Date:
        </strong>

        {{ complaint.created | formatDate('local', '-') }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Customer:
        </strong>

        {{ accountNameById[complaint.printCustomerId] }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Provider:
        </strong>

        {{ accountNameById[complaint.printProviderId] }}
      </v-flex>
    </v-layout>

    <v-layout class="mt-2">
      <v-flex sm4
        v-if="complaint.metadata && complaint.metadata.clientExpectProductionCompensation">
        <strong>
          Client Expected Production Compensation:
        </strong>

        {{ complaint.metadata.clientExpectedCompensation.production }}
      </v-flex>

      <v-flex sm4 v-if="complaint.metadata && complaint.metadata.clientExpectShippingCompensation">
        <strong>
          Client Expected Shipping Compensation:
        </strong>

        {{ complaint.metadata.clientExpectedCompensation.shipping }}
      </v-flex>
    </v-layout>

    <v-layout class="mt-2">
      <v-flex sm4
        v-if="complaint.metadata && complaint.metadata.inkrouterExpectProductionCompensation">
        <strong>
          IR Expected Production Compensation:
        </strong>

        {{ complaint.metadata.inkrouterExpectedCompensation.production }}
      </v-flex>

      <v-flex sm4
        v-if="complaint.metadata && complaint.metadata.inkrouterExpectShippingCompensation">
        <strong>
          IR Expected Shipping Compensation:
        </strong>

        {{ complaint.metadata.inkrouterExpectedCompensation.shipping }}
      </v-flex>
    </v-layout>

    <v-layout class="mt-2">
      <v-flex sm4
        v-if="complaint.metadata">
        <strong>
          Provider Agreed Production Compensation:
        </strong>

        {{ complaint.metadata.providerAgreedCompensation
          && complaint.metadata.providerAgreedCompensation.production }}
        <complaint-state-icon
          v-if="complaint.metadata.providerAgreedCompensateProduction != null"
          :state="Boolean(complaint.metadata.providerAgreedCompensateProduction)" />
      </v-flex>

      <v-flex sm4
        v-if="complaint.metadata">
        <strong>
          Provider Agreed Shipping Compensation:
        </strong>

        {{ complaint.metadata.providerAgreedCompensation
          && complaint.metadata.providerAgreedCompensation.shipping }}
        <complaint-state-icon
          v-if="complaint.metadata.providerAgreedCompensateShipping != null"
          :state="Boolean(complaint.metadata.providerAgreedCompensateShipping)" />
      </v-flex>
    </v-layout>

    <v-layout class="mt-2">
      <v-flex sm4
        v-if="complaint.metadata && complaint.metadata.lateShippingInfo">
        <strong>
          Expected Ship Date:
        </strong>

        {{ complaint.metadata.lateShippingInfo.expectedShipDate }}
      </v-flex>

      <v-flex sm4
        v-if="complaint.metadata && complaint.metadata.lateShippingInfo">
        <strong>
          Actual Ship Date:
        </strong>

        {{ complaint.metadata.lateShippingInfo.actualShipDate }}
      </v-flex>

      <v-flex sm4
        v-if="complaint.metadata && complaint.metadata.lateShippingInfo">
        <strong>
          Late:
        </strong>

        {{ diffDatesHours(complaint.metadata.lateShippingInfo.actualShipDate,
        complaint.metadata.lateShippingInfo.expectedShipDate) }} hours
      </v-flex>
    </v-layout>

    <v-layout class="mt-5">
      <vbt-table
        v-if="complaint.metadata && complaint.metadata.reasons"
        :headers="headers"
        :items="complaint.metadata.reasons"
      >
        <template #category="{ item: { category } }">
          {{ category }}
        </template>

        <template #reason="{ item: { reason } }">
          {{ reason }}
        </template>
      </vbt-table>
    </v-layout>
  </vbt-content-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { diffDatesHours } from '@helpers/dates';

import ComplaintApprove from './ComplaintApprove.vue';
import ComplaintReview from './ComplaintReview.vue';
import ComplaintStatusLabel from './ComplaintStatusLabel.vue';
import ComplaintStateIcon from './ComplaintStateIcon.vue';

const headers = Object.freeze([
  { text: 'Category', value: 'category' },
  { text: 'Reason', value: 'reason' },
]);

export default {
  name: 'ComplaintInfo',

  components: {
    ComplaintStatusLabel,
    ComplaintApprove,
    ComplaintReview,
    ComplaintStateIcon,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingError: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      headers,
    };
  },

  methods: {
    diffDatesHours,
  },

  computed: {
    ...mapState('complaints', ['complaint']),

    ...mapGetters('accounts', ['accountNameById']),
    ...mapGetters('enums', ['complaintStatusByStatusId']),
  },
};
</script>
