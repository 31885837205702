var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vbt-content-box',{attrs:{"loading":_vm.loading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"title":"History"}},[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.complaintHistory,"page-size":5,"show-paginator":""},scopedSlots:_vm._u([{key:"processed",fn:function(ref){
var processed = ref.item.processed;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(processed,'local', '-'))+" ")]}},{key:"messageId",fn:function(ref){
var messageId = ref.item.messageId;
return [_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
          name: 'messages',
          query: {
            pageNum: '1',
            messageId: messageId,
          },
        },"target":"_blank"}},[_vm._v(" "+_vm._s(messageId)+" ")])]}},{key:"action",fn:function(ref){
        var action = ref.item.action;
return [_vm._v(" "+_vm._s(_vm.complaintActionsByActionId[action])+" ")]}},{key:"status",fn:function(ref){
        var status = ref.item.status;
return [_vm._v(" "+_vm._s(_vm.complaintStatusByStatusId[status])+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }