<template>
  <vbt-dialog
    v-model="dialog"
    :disable-submit-btn="disableSubmit"
    title="Approve complaint"
    submit-btn-name="Approve"
    @submit="approveComplaint"
    @close="resetForm"
  >
    <template #activator="{ on }">
      <v-btn
        :loading="loading"
        x-small
        color="success"
        :disabled="status != 0"
        v-on="on"
      >
        Approve
      </v-btn>
    </template>

    <v-layout row align-center class="mt-2">
      <v-checkbox
        v-model="updatedNeedCompensateProduction"
        class="vbt-hide-form-control-details mt-2 mr-5"
        label="Ask for production compensation"
      />
      <v-text-field
        v-model.trim.number="updatedProductionCompensation"
        :disabled="!updatedNeedCompensateProduction"
        label="Production compensation amount"
        type="number"
        class="w-50"
      />
    </v-layout>

    <v-layout row align-center>
      <v-checkbox
        v-model="updatedNeedCompensateShipping"
        class="vbt-hide-form-control-details mt-2 mr-5"
        label="Ask for shipping compensation"
      />
      <v-text-field
        v-model.trim.number="updatedShippingCompensation"
        :disabled="!updatedNeedCompensateShipping"
        label="Shipping compensation amount"
        type="number"
        class="w-50"
      />
    </v-layout>

    <v-textarea
      v-model.trim="comment"
      label="Comment"
      no-resize
      outlined
      class="mt-5"
    />
  </vbt-dialog>
</template>

<script>
import { mapState } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import { ComplaintsApiService } from '../_services/complaints.api.service';

export default {
  name: 'ComplaintApprove',

  props: {
    loading: Boolean,
  },

  data() {
    return {
      dialog: false,

      comment: '',
      updatedProductionCompensation: null,
      updatedShippingCompensation: null,
      updatedNeedCompensateProduction: null,
      updatedNeedCompensateShipping: null,
    };
  },

  computed: {
    ...mapState('complaints', {
      complaintId: ({ complaint: { id } }) => id,
      status: ({ complaint: { status } }) => status,
      metadata: ({ complaint: { metadata } }) => metadata,
    }),
    productionCompensation() {
      return (this.metadata && this.metadata.inkrouterExpectedCompensation
      && this.metadata.inkrouterExpectedCompensation.production) || null;
    },
    shippingCompensation() {
      return (this.metadata && this.metadata.inkrouterExpectedCompensation
      && this.metadata.inkrouterExpectedCompensation.shipping) || null;
    },
    needCompensateProduction() {
      return (this.metadata && this.metadata.inkrouterExpectProductionCompensation) || null;
    },
    needCompensateShipping() {
      return (this.metadata && this.metadata.inkrouterExpectShippingCompensation) || null;
    },
    disableSubmit() {
      return !this.comment;
    },
  },

  watch: {
    dialog(state) {
      if (state) {
        this.updatedProductionCompensation = this.productionCompensation;
        this.updatedShippingCompensation = this.shippingCompensation;
        this.updatedNeedCompensateProduction = this.needCompensateProduction;
        this.updatedNeedCompensateShipping = this.needCompensateShipping;
      }
    },
  },

  methods: {
    approveComplaint() {
      this.$VBlackerTheme.alert.warning({ text: 'Approve complaint?' }, () => this.wrapToLoadingFn({
        req: ComplaintsApiService.approveComplaint.bind({}, {
          id: this.complaintId,
          data: {
            comment: this.comment,
            productionCompensation:
              this.updatedNeedCompensateProduction ? this.updatedProductionCompensation : null,
            shippingCompensation:
              this.updatedNeedCompensateShipping ? this.updatedShippingCompensation : null,
          },
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.dialog = false;
          this.$emit('success');
          this.$VBlackerTheme.notification.success('Successfully approved');
        },
      }));
    },

    resetForm() {
      this.comment = '';
    },

    wrapToLoadingFn,
  },
};
</script>
