<template>
  <vbt-dialog
    v-model="dialog"
    :disable-submit-btn="disableSubmit"
    title="Review complaint"
    submit-btn-name="Review"
    @submit="reviewComplaint"
    @close="resetForm"
  >
    <template #activator="{ on }">
      <v-btn
        :loading="loading"
        x-small
        color="success"
        :disabled="complaintStatus != 6"
        class="ml-2"

        v-on="on"
      >
        Review
      </v-btn>

    </template>

    <v-select
      v-model="status"
      :items="complaintStatusSelectOptions"
      label="Decision"
    />

    <v-layout row align-center class="mt-2">
      <v-checkbox
        v-model="updatedNeedCompensateProduction"
        class="vbt-hide-form-control-details mt-2 mr-5"
        label="Ask for production compensation"
      />
      <v-text-field
        v-model.trim.number="updatedProductionCompensation"
        :disabled="!updatedNeedCompensateProduction"
        label="Production compensation amount"
        type="number"
        class="w-50"
      />
      <v-flex
        v-if="metadata && metadata.providerExpectedCompensation != null">
        <strong>
          Provider Agreed Production Compensation:
        </strong>

        {{ metadata.providerExpectedCompensation.production }}
      </v-flex>
    </v-layout>

    <v-layout row align-center class="mt-2">
      <v-flex
        v-if="metadata && metadata.providerExpectedCompensation">
        <strong>
          Provider Agreed Production Compensation:
        </strong>

        {{ metadata.providerExpectedCompensation.production }}
      </v-flex>
      <v-flex
        v-if="metadata && !metadata.providerExpectedCompensation">
        <strong>
          Provider Agreed Production Compensation:
        </strong>

        <complaint-state-icon
          v-if="metadata.providerAgreedCompensateProduction != null"
          :state="Boolean(metadata.providerAgreedCompensateProduction)" />
      </v-flex>
    </v-layout>

    <v-layout row align-center mt-3>
      <v-checkbox
        v-model="updatedNeedCompensateShipping"
        class="vbt-hide-form-control-details mt-2 mr-5"
        label="Ask for shipping compensation"
      />
      <v-text-field
        v-model.trim.number="updatedShippingCompensation"
        :disabled="!updatedNeedCompensateShipping"
        label="Shipping compensation amount"
        type="number"
        class="w-50"
      />
      <v-flex sm4
        v-if="metadata && metadata.providerExpectedCompensation != null">
        <strong>
          Provider Agreed Shipping Compensation:
        </strong>

        {{ metadata.providerExpectedCompensation.shipping }}
      </v-flex>
    </v-layout>

    <v-layout row align-center>
      <v-flex
        v-if="metadata && metadata.providerExpectedCompensation">
        <strong>
          Provider Agreed Shipping Compensation:
        </strong>

        {{ metadata.providerExpectedCompensation.shipping }}
      </v-flex>
      <v-flex
        v-if="!metadata.providerExpectedCompensation">
        <strong>
          Provider Agreed Shipping Compensation:
        </strong>

        <complaint-state-icon
          v-if="metadata.providerAgreedCompensateProduction != null"
          :state="Boolean(metadata.providerAgreedCompensateShipping)" />
      </v-flex>
    </v-layout>

    <v-textarea
      v-model.trim="comment"
      label="Comment"
      no-resize
      outlined
      class="mt-5"
    />
  </vbt-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import ComplaintStateIcon from './ComplaintStateIcon.vue';

import { ComplaintsApiService } from '../_services/complaints.api.service';

export default {
  name: 'ComplaintReview',

  components: {
    ComplaintStateIcon,
  },

  props: {
    loading: Boolean,
  },

  data() {
    return {
      dialog: false,
      status: null,
      comment: '',
      updatedProductionCompensation: null,
      updatedShippingCompensation: null,
      updatedNeedCompensateProduction: null,
      updatedNeedCompensateShipping: null,
    };
  },

  computed: {
    ...mapGetters('enums', ['complaintReviewStatusByStatusId']),
    ...mapState('complaints', {
      complaintId: ({ complaint: { id } }) => id,
      complaintStatus: ({ complaint: { status } }) => status,
      metadata: ({ complaint: { metadata } }) => metadata,
    }),
    ...mapState('enums', {
      complaintStatusSelectOptions(state) {
        const { complaintReviewStatuses = [] } = state.enums;
        return complaintReviewStatuses.map(({ name: text, value }) => ({ text, value }));
      },
    }),
    productionCompensation() {
      return (this.metadata && this.metadata.inkrouterExpectedCompensation
      && this.metadata.inkrouterExpectedCompensation.production) || null;
    },
    shippingCompensation() {
      return (this.metadata && this.metadata.inkrouterExpectedCompensation
      && this.metadata.inkrouterExpectedCompensation.shipping) || null;
    },
    needCompensateProduction() {
      return (this.metadata && this.metadata.inkrouterExpectProductionCompensation) || null;
    },
    needCompensateShipping() {
      return (this.metadata && this.metadata.inkrouterExpectShippingCompensation) || null;
    },

    disableSubmit() {
      return !this.comment;
    },
  },

  watch: {
    dialog(state) {
      if (state) {
        this.updatedProductionCompensation = this.productionCompensation;
        this.updatedShippingCompensation = this.shippingCompensation;
        this.updatedNeedCompensateProduction = this.needCompensateProduction;
        this.updatedNeedCompensateShipping = this.needCompensateShipping;
      }
    },
    status(state) {
      if (state === 3) {
        this.updatedProductionCompensation = this.productionCompensation;
        this.updatedShippingCompensation = this.shippingCompensation;
        this.updatedNeedCompensateProduction = true;
        this.updatedNeedCompensateShipping = true;
      }
      if (state === 4) {
        this.updatedProductionCompensation = this.productionCompensation;
        this.updatedShippingCompensation = this.shippingCompensation;
        this.updatedNeedCompensateProduction = true;
        this.updatedNeedCompensateShipping = true;
      }
      if (state === 5) {
        this.updatedProductionCompensation = 0;
        this.updatedShippingCompensation = 0;
        this.updatedNeedCompensateProduction = false;
        this.updatedNeedCompensateShipping = false;
      }
    },
  },

  methods: {
    reviewComplaint() {
      this.$VBlackerTheme.alert.warning({ text: 'Review complaint?' }, () => this.wrapToLoadingFn({
        req: ComplaintsApiService.reviewComplaint.bind({}, {
          id: this.complaintId,
          data: {
            status: this.status,
            comment: this.comment,
            productionCompensation:
              this.updatedNeedCompensateProduction ? this.updatedProductionCompensation : null,
            shippingCompensation:
              this.updatedNeedCompensateShipping ? this.updatedShippingCompensation : null,
          },
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.dialog = false;
          this.$emit('success');
          this.$VBlackerTheme.notification.success('Successfully reviewed');
        },
      }));
    },

    resetForm() {
      this.comment = '';
    },

    wrapToLoadingFn,
  },
};
</script>
